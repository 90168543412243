<template>
  <form
    @submit.prevent="handleSubmit"
    ref="form"
    class="artist-emboarding-telephone__form-wrapper"
    novalidate>
    <template v-if="hasSentPhoneNumber">
      <FieldInput
        v-model="code"
        :label="$t('artist.phoneValidation.inputCode')"
        :placeholder="$t('artist.phoneValidation.inputCode')"
        isAutofocus
        id="phone-code"
        maxlength="6"
        type="text"
        required>
      </FieldInput>
      <Paragraph
        :size="'sm'"
        class="my-base">
        <template v-if="codeCountdown > 0">
          {{ $t('artist.phoneValidation.codeExpiresIn') }}
          <span>
            {{ $t('artist.phoneValidation.timeout', { timeout: codeCountdown }) }}
          </span>
        </template>
        <template v-else>
          {{ $t('artist.phoneValidation.codeExpired') }}
        </template>
      </Paragraph>
      <Loader
        v-if="isLoading"
        class="mt-lg">
      </Loader>
      <Button
        v-else-if="codeCountdown <= 0"
        @click="handleSendPhoneNumber"
        isFluid
        type="button"
        class="mt-lg">
        {{ $t('artist.phoneValidation.resendCode') }}
      </Button>
      <Button
        v-else
        :isFluid="!$mediaQueries.isDesktop"
        :class="[
          'mr-auto',
          $mediaQueries.isDesktop ? 'mt-md' : 'mt-auto',
        ]"
        type="submit">
        {{ $t('common.confirm') }}
      </Button>
    </template>

    <template v-else>
      <Loader
        v-if="isLoading"
        class="mt-lg">
      </Loader>
      <template v-else>
        <FieldInput
          v-model="phoneNumber"
          :label="$t('common.mobilePhoneNumber')"
          :placeholder="$t('common.mobilePhoneNumber')"
          :minlength="9"
          :maxlength="locale && locale.locale === 'fr' ? 10 : 12"
          :isLiveValidation="false"
          :key="hasSentPhoneNumber"
          isAutofocus
          hasLeftIcons
          type="tel"
          id="phone-number"
          required>
          <template #icons-left>
            <span class="artist-emboarding-telephone__input-prefix">
              {{ phonePrefix }}
            </span>
          </template>
        </FieldInput>
        <Paragraph
          :size="'sm'"
          class="mt-md">
          {{ $t('artist.phoneValidation.sendCode') }}
          <b>{{ formattedPhoneNumberWithPrefix }}</b>
        </Paragraph>
        <Loader
          v-if="isLoading"
          class="mt-lg">
        </Loader>
        <Button
          v-else
          :isFluid="!$mediaQueries.isDesktop"
          class="mr-auto mt-lg"
          type="submit">
          {{ $t('artist.phoneValidation.send') }}
        </Button>
      </template>
    </template>
  </form>
</template>

<script>

import {
  mapState,
  mapActions
}                     from 'vuex';

import FieldInput     from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';
import Loader         from '../../../components/atoms/Loader/a-Loader.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'o-ArtistEmboardingTelephone',
  components: {
    FieldInput,
    Button,
    Loader,
    Paragraph,
  },
  data: () => ({
    isLoading: false,
    hasSentPhoneNumber: false,
    phoneNumber: '',
    code: '',
    codeCountdown: 60,
    countdownIntervalId: null,
  }),
  computed: {
    ...mapState('User', ['locale']),
    phonePrefix() {
      return this.locale?.locale === 'fr' ? '+33' : '+44';
    },
    formattedPhoneNumberWithPrefix() {
      const regex = /0?(\d{9,11})/g;
      const match = this.phoneNumber.toString().match(regex);
      const hasZeroPrefix = this.phoneNumber.charAt(0) === '0';

      if (!match) {
        return hasZeroPrefix
          ? `${this.phonePrefix}${this.phoneNumber.substring(1)}`
          : `${this.phonePrefix}${this.phoneNumber}`;
      }

      const formattedPhoneNumberWithPrefix = hasZeroPrefix
        ? `${this.phonePrefix}${match[0].substring(1)}`
        : `${this.phonePrefix}${match[0]}`;

      return formattedPhoneNumberWithPrefix;
    },
  },
  methods: {
    ...mapActions({
      editIdentity: 'User/EDIT_IDENTITY',
      verifyPhoneCode: 'User/VERIFY_PHONE_NUMBER_CODE',
    }),
    async handleSubmit() {
      const isFormValid = this.$refs.form.checkValidity();

      if (!isFormValid) return;
      if (!this.hasSentPhoneNumber) {
        await this.handleSendPhoneNumber();
        this.$emit('code-sent');

        return;
      }

      try {
        this.isLoading = true;

        const isCodeStillValid = this.codeCountdown > 0;
        const input = {
          input: {
            PhoneNumber: this.formattedPhoneNumberWithPrefix.toString(),
            Code: this.code.toString(),
          },
        };

        if (!isFormValid || !isCodeStillValid) return;

        await this.verifyPhoneCode(input) && this.$emit('success');
      } finally {
        this.isLoading = false;
      }
    },
    startCodeCountdown() {
      this.codeCountdown = 60;
      this.countdownIntervalId = setInterval(() => {
        this.codeCountdown > 0
          ? this.codeCountdown = this.codeCountdown - 1
          : clearInterval(this.countdownIntervalId);
      }, 1000);
    },
    async handleSendPhoneNumber() {
      const params = {
        input: {
          PhoneNumber: this.formattedPhoneNumberWithPrefix,
        },
      };

      try {
        this.isLoading = true;

        const hasSentValidPhoneNumber = await this.editIdentity(params);

        if (hasSentValidPhoneNumber) {
          this.hasSentPhoneNumber = true;
          this.startCodeCountdown();
        }
      } finally {
        this.isLoading = false;
      }
    },
  }
}

</script>

<style lang="scss">

.artist-emboarding-telephone {
  &__form-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    @media screen and ($desktop) {
      flex: 0;
      min-width: 350px;
      align-self: start;
    }
  }

  &__input-prefix {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-right: var(--space-base);
    margin-right: calc(-1 * var(--space-base));
    font-size: var(--text-xs);
    line-height: var(--lineheight-lg);
    color: var(--color-black);
    border-right: 1px solid var(--color-grey-semi);
  }

  &__already-verified {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

</style>
